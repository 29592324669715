<template>
    <div style="width: 100%;height: 100vh; color:#fff;padding: 5px;">
        <div style="margin-top: 120px;padding: 3%;maxPadding: 40px;height: calc(100% - 200px);">
            <a-input-group :style="{ width: '100%', height: '150px' }">
                <a-input v-model="title" :style="{ width: '100%', height: '150px' }" placeholder="输入文章标题">
                    <template #prefix>
                        <p5-button>文章标题</p5-button>
                        <a-select :style="{ width: '100px', margin: '0px 10px' }" placeholder="文章" default-value="诗歌"
                            v-model="type">
                            <a-option>诗歌</a-option>
                            <a-option>文章</a-option>
                        </a-select>
                    </template>

                    <template #append>


                        <a-space direction="vertical" :style="{ width: '100%', display: 'block' }">
                            <a-upload :show-file-list="false" @change="onChange" :auto-upload="false">
                                <template #upload-button>
                                    <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                        }`">
                                        <div class="arco-upload-list-picture custom-upload-avatar" v-if="file && file.url">
                                            <img :src="cover" />
                                            <div class="arco-upload-list-picture-mask">
                                                <IconEdit />
                                            </div>
                                            <a-progress v-if="file.status === 'uploading' && file.percent < 100"
                                                :percent="file.percent" type="circle" size="mini" :style="{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    top: '50%',
                                                    transform: 'translateX(-50%) translateY(-50%)',
                                                }" />
                                        </div>
                                        <div class="arco-upload-picture-card" v-else>
                                            <div class="arco-upload-picture-card-text">
                                                <IconPlus />
                                                <div style="margin-top: 10px; font-weight: 600">Upload</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </a-upload>
                        </a-space>
                    </template>
                </a-input>
                <a-date-picker style="width: 150px;height: 150px;" v-model="date" />
            </a-input-group>
            <mavon-editor v-model="content" ref="md"
                style="width: 100%;height: calc(100% - 70px);margin: 10px auto 0px auto;z-index: 1001;" @change="Change"
                @imgAdd="imgAdd" @save="Save" />
        </div>
    </div>
</template>
    
<script>
import { P5Notification } from 'p5-ui'
import axios from 'axios'
import { ref, getCurrentInstance } from 'vue'


export default {


    setup(props, ctx) {
        const { proxy } = getCurrentInstance();

        const switchPage = (page) => {
            ctx.emit('callPage', page)
        }

        const file = ref();

        const onChange = (_, currentFile) => {

            var formdata = new FormData();
            formdata.append('file', currentFile.file);

            axios({
                url: '/api/uploadFile',
                method: 'post',
                data: formdata,
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then((e) => {
                console.log(e);
                if (e.data.status == "YES") {

                    file.value = currentFile;
                    proxy.cover = e.data.url;
                }
            })
        };


        return {
            switchPage,
            file,
            onChange
        }

    },
    name: 'ReviseArticle',
    data() {
        let _this = this;
        axios.post('/api/getOneArticle', { "id": this.currentProp.id + '' }).then((res) => {
            if (res.data.status == 'YES') {
                _this.content = res.data.data[0].body
                _this.title = res.data.data[0].title
                _this.type = res.data.data[0].type
                _this.date = res.data.data[0].writing_date
                _this.cover = res.data.data[0].cover
            }

        })
        return {
            content: '',
            title: '',
            date: '',
            cover: '',
            type: '诗歌',
            id: this.currentProp.id
        }
    },
    props: {
        currentProp: {
            type: Object
        },
    },
    methods: {
        Change: function () {
            let _this = this
            setTimeout(() => {
                if (_this.content != '') {
                    _this.$emit('setPage', { "switch": "check", "msg": "章未保存，是否继续跳转页面？" });
                } else {
                    _this.$emit('setPage', { "switch": "true" });
                }
            })
        },
        Save: function () {
            let _this = this;
            if (this.title == '' || this.date == '') {
                P5Notification({
                    content: 'Joker，文章标题和日期呢', character: 'ann',
                    top: document.body.clientHeight * 0.2, left: document.body.clientWidth * 0.1
                })
                return setTimeout(delP5UIMsg, 3000)
            }

            axios.post('/api/reviseArticle', { "id": _this.id, "title": _this.title, "date": _this.date, "content": _this.content, "type": _this.type, "cover": _this.cover }).then(res => {
                console.log(res.data);
                if (res.data && res.data.status == 'YES') {
                    P5Notification({
                        content: '保存成功', character: 'ann',
                        top: document.body.clientHeight * 0.2, left: document.body.clientWidth * 0.1
                    })
                    _this.title = '';
                    _this.date = '';
                    _this.content = '';
                }
            })
            console.log(this.title);
            console.log(this.date);
            console.log(this.content);
        },
        imgAdd: function (pos, $file) {
            console.log($file);
            var formdata = new FormData();
            formdata.append('file', $file);
            let _this = this;
            axios({
                url: '/api/uploadFile',
                method: 'post',
                data: formdata,
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then((e) => {
                console.log(e);
                if (e.data.status == "YES") {
                    _this.$refs.md.$img2Url(pos, "/" + e.data.url);
                } else {
                    P5Notification({
                        content: 'Joker，图片上传失败了啊。', character: 'ann',
                        top: document.body.clientHeight * 0.2, left: document.body.clientWidth * 0.1
                    })
                    _this.$refs.md.$imgDel([pos, $file]);
                }
            })
        },


    },
    emits: ["setPage"],
    mounted() {
        this.$emit('setPage', { "page_name": 'MenuHome', "color_0": '#000', 'color_1': '#ff0022', 'color_2': '#ff0022' });
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.arco-upload-list-picture {
    width: 200px;
    height: 120px;
}
</style>
    