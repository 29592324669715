<template>
  <Index />
</template>

<script>
import Index from './Index.vue'

export default {
  name: 'App',
  components: {
    Index
  }
}

</script>

<style></style>
