import { createApp } from 'vue'
import App from './App.vue'
import ArcoVue from '@arco-design/web-vue'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css' // 样式记得导入喔
import '@arco-design/web-vue/dist/arco.css'
import P5UI from 'p5-ui'
import 'p5-ui/dist/style.css'


createApp(App).use(ArcoVue).use(P5UI).use(mavonEditor).mount('#app')


Date.prototype.Clone = function () {
    return new Date(this.valueOf());
}

