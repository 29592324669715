<template>
    <div style="width: 100%;height: 100vh; padding: 5px;
                                                    background: url(/static/dist/static/img/queen_2.jpg) no-repeat center center;
                                                    background-color: #fefeeb; 
                                                    background-size:100% auto;
                                                    overflow-x: hidden;
                                                    /* overflow-y: hidden; */
                                                                                                                    ">
        <div style="padding: 140px 0px 50px 0px;background-size:100% auto;">

            <div class="poetry-list">
                <div :style="{ maxWidth: '360px', width: '100%', margin: bodyW >= 630 ? '0px  25px' : '0PX  auto' }">
                    <div v-for="(value, key) in Poetrys">
                        <div v-if="key % 2 == 0" style="width: 100%; marginTop: 4%">
                            <a-card :style="{ width: '100%', margin: '5px auto 0px auto' }" :title="value.data.title"
                                hoverable>
                                <template #extra>
                                    <a-link @click="showPoetry(key)">查看</a-link>
                                </template>
                                <a-collapse :key="current">
                                    <a-collapse-item
                                        :header="value.switch ? value.title : value.title + '\r\n' + value.data.body"
                                        @click="switchPoetryTitle(value)" :style="value.style" :bordered="false"
                                        :show-expand-icon="false">
                                        <div style="white-space: pre-wrap;">{{ value.data.body }}</div>
                                    </a-collapse-item>
                                </a-collapse>
                            </a-card>
                        </div>
                    </div>
                </div>

                <div :style="{ maxWidth: '360px', width: '100%', margin: bodyW >= 630 ? '0px  25px' : '0PX  auto' }">
                    <div v-for="(value, key) in Poetrys">
                        <div v-if="key % 2 == 1" :style="{ maxWidth: '360px', width: '100%', marginTop: '4%' }">
                            <a-card :style="{ width: '100%', margin: '5px auto 0px auto' }" :title="value.data.title"
                                hoverable>
                                <template #extra>
                                    <a-link @click="showPoetry(key)">查看</a-link>
                                </template>
                                <a-collapse :key="current">
                                    <a-collapse-item
                                        :header="value.switch ? value.title : value.title + '\r\n' + value.data.body"
                                        @click="switchPoetryTitle(value)" :style="value.style" :bordered="false"
                                        :show-expand-icon="false">
                                        <div style="white-space: pre-wrap;">{{ value.data.body }}</div>
                                    </a-collapse-item>
                                </a-collapse>
                            </a-card>
                        </div>
                    </div>
                </div>
            </div>

            <a-pagination @change="switchPage" :total="count" :page-size="10" :current="current"
                :style="{ margin: '20px auto 0px auto', width: '400px', maxWidth: '100%' }" :size="'small'" />
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'PoetryList',
    data() {
        console.log(this.currentProp);
        this.bodyW = document.body.clientWidth * 1
        let page = this.currentProp.page;
        let count = this.currentProp.count;
        let Poetrys = this.currentProp.Poetrys;
        if (Poetrys == undefined) {
            this.current = 1;
            this.getPageData(0)
        }
        else {
            console.log(count, page, Poetrys);
            this.count = count
            this.current = page
            this.Poetrys = Poetrys
        }
    },
    props: {
        currentProp: {
            type: Object,
        },
    },
    emits: ["setPage", "callPage"],
    methods: {
        switchPoetryTitle: function (value) {
            if (value.switch == undefined) {
                value.switch = true;
            } else if (value.switch == true) {
                value.switch = undefined
            }
        },
        switchPage: function (current) {
            this.current = current;
            this.getPageData((current - 1) * 10)
        },
        getPageData: function (num) {
            let _this = this;
            if (num == undefined)
                num = 0
            axios.post('/api/getArticleList', { "pageNum": num, "type": "诗歌" }).then(res => {
                console.log(res);
                if (res.data && res.data.status == 'YES') {

                    if (res.data.count)
                        _this.count = res.data.count * 1;
                    let poes = [];
                    for (let p of res.data.data) {
                        let poe = {
                            title: (p.chinese_name == '' ? p.name : p.chinese_name) + (p.translator_name != '' ? ' / ' + p.translator_name : ''),
                            data: p,
                            style: {
                                textOverflow: ' -o-ellipsis-lastline',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                '-webkit-line-clamp': 2,
                                lineClamp: 2,
                                '-webkit-box-orient': 'vertical',
                                whiteSpace: 'pre-wrap',
                                fontSize: '34px',
                                lineHeight: '32px',
                                letterSpacing: '2px',
                                // paddingBottom: '10px'
                            }
                        }
                        poes.push(poe);
                    }
                    _this.Poetrys = poes;
                    // 每次切换页面通知全局组件页面数据，在退出组件页面后再进入时保留当前页面
                    _this.$emit('setPage', { "page": _this.current, "count": _this.count, "Poetrys": _this.Poetrys });
                }
            })
        },
        showPoetry: function (index) {
            this.$emit('callPage', { "page": 'PoetryBody', "data": { data: JSON.parse(JSON.stringify(this.Poetrys)), index: index, page: this.current, type: '固定顺序' } });
        }
    },
    mounted() {
        this.$emit('setPage', { "page_name": 'MenuHome', "color_0": '#ffe343', 'color_1': '#ff0022', 'color_2': '#ff0022' });
    }
}


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 730px) {
    .poetry-list {
        /* display: flex;
        justify-content: center; */
        margin: 0px auto;
        width: 100%
    }
}

@media (min-width: 730px) {
    .poetry-list {
        display: flex;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }
}

.arco-pagination-list {
    margin: auto !important;
    width: 300px;
}

.arco-pagination {
    justify-content: center;
}
</style>
