<template>
  <a-modal v-model:visible="visible" title="上传留言" @cancel="handleCancel" @before-ok="handleBeforeOk">
    <a-textarea v-model="remark.data" placeholder="输入留言" allow-clear />
  </a-modal>

  <a-modal v-model:visible="visible2" title="文章列表" @cancel="handleCancel" @before-ok="handleBeforeOk">
    <a-space>
      <a-select :style="{ width: '200px' }" v-model="select_data_0" @change="filter">
        <a-option v-for="value of Object.keys(select_data)">{{ value }}</a-option>
      </a-select>
      <a-select :style="{ width: '200px' }" :options="select_data[select_data_0] || []" v-model="select_data_1"
        @change="filter" />
    </a-space>
    <a-list>
      <a-list-item v-for="v in showList" :key="v">
        <div @click="revise(v)">{{ v.title }}</div>
        <template #actions>
          <input type="number" v-model="v.sort" style="width: 50px;">
          <div style="width: 50px;height: 30px;line-height: 30px;background-color: antiquewhite;text-align: center;"
            @click="setSort(v)">
            修改
          </div>
        </template>
      </a-list-item>
    </a-list>
  </a-modal>

  <div style="width: 100%;height: 100vh; color:#fff;padding: 5px;">
    <div style="margin-top: 140px;display: flex;padding: 40px;">
      <a-card class="card-demo" title="上传文章" hoverable style="height:150px;width:48%"
        @click="switchPage('UploadArticle')">
        文章、詩、おすすめの記事をアップロードする
      </a-card>
      <a-card class="card-demo" title="上传留言" hoverable style="height:150px;width:48%" @click="handleClick">
        メッセージをアップロード
      </a-card>
      <a-card class="card-demo" title="修改文章" hoverable style="height:150px;width:48%" @click="showArticleList">
        記事や詩の改訂
      </a-card>
    </div>
  </div>
</template>
  
<script>
import { reactive, ref } from 'vue';
import axios from 'axios'

export default {
  setup(props, ctx) {
    console.log('start');
    const remark = reactive({
      data: '',
    });
    const visible = ref(false);
    const visible2 = ref(false);
    const switchPage = (page) => {
      ctx.emit('callPage', page)
    }
    const handleClick = () => {
      visible.value = true;
    };
    const handleBeforeOk = (done) => {
      console.log(remark);
      if (remark.data != '') {
        axios.post('/api/uploadRemark', { "remark": remark.data, "username": "一只大乌龟", "type": "self" }).then((e) => {
          if (e.data.status == "YES")
            done()
        })
      }
      // window.setTimeout(() => {
      //   done()
      // }, 3000)

    };
    const handleCancel = () => {
      visible.value = false;
    }

    const showArticleList = () => {
      filter()
      visible2.value = true
    }

    const select_data_0 = ref("诗歌");
    const select_data_1 = ref("我的")
    const select_data = {
      "诗歌": ["我的", "其他"],
      "文章": ["我的", "其他"]
    }

    let data = [];
    if (Object.keys(data).length == 0) {
      axios.post('/api/getAllArticleList').then((e) => {
        console.log(e);
        if (e.data.status == "YES") {
          data = e.data.data;
        }
      })
    }

    let showList = ref([]);
    const filter = () => {
      showList.value = [];
      console.log(data);
      for (let d of data) {
        if (select_data_0._value == d.type && select_data_1._value == (d.user == '123' ? "我的" : "其他")) {
          showList.value.push(d);
        }
      }
    }
    filter();

    const setSort = (v) => {
      console.log(v);
      axios.post('/api/setArticleSort', { "id": v.id + '', 'sort': v.sort != '' ? v.sort + '' : 'null' }).then((e) => {
        console.log(e);
        if (e.data.status == "YES") {
          data = e.data.data;
        }
      })
    }

    const revise = (v) => {
      ctx.emit('callPage', { page: 'ReviseArticle', data: { id: v.id } })
    }


    return {
      switchPage,
      handleClick,
      handleBeforeOk,
      handleCancel,
      visible,
      remark,
      visible2,
      showArticleList,
      select_data,
      select_data_0,
      select_data_1,
      data,
      filter,
      showList,
      setSort,
      revise
    }
  },
  name: 'AdminPage',
  mounted() {
    this.$emit('setPage', { "page_name": 'MenuHome', "color_0": '#000', 'color_1': '#ff0022', 'color_2': '#ff0022' });
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  