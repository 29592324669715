<template>
    <div
        style="width: 100%;height: 100vh; color:#fff;padding: 5px;background-color: #0080ff;overflow-x:hidden;overflow-y:hidden;">
        <swiper :effect="'cards'" :grabCursor="true" :modules="modules" :direction="bodyW < 600 ? 'horizontal' : 'vertical'"
            :loop="true" :rotate="false"
            :style="{ marginTop: bodyW < 600 ? '7vh' : '140px', height: '80%', width: '500px', maxWidth: '90%' }"
            @realIndexChange="switchPoetry">

            <swiper-slide v-for="key in [0, 1, 2]" style="background-color: #51a8ff;">
                <div class="poetry-body" v-if="poetrys[index + key] != undefined">
                    <div>
                        <H4 align="center">{{ poetrys[index + key].data.title }}</H4>
                        <span
                            style="font-size: 12px;font-style:italic;text-align:center;display:inline-block;width: 100%;">{{
                                (poetrys[index + key].data.chinese_name == '' ?
                                    poetrys[index + key].data.name : poetrys[index + key].data.chinese_name) +
                                (poetrys[index + key].data.translator_name != '' ?
                                    ' / ' + poetrys[index + key].data.translator_name : '') }}</span>
                        <div style="white-space: pre-wrap;font-size: 1rem;">
                            {{ poetrys[index + key].data.body }}
                        </div>
                    </div>
                    <div v-if="poetrys[index + key].data.original != undefined">
                        <br>
                        <br>
                        <H4 align="center">{{ poetrys[index + key].data.original_title }}</H4>
                        <br>
                        <div style="white-space: pre-wrap;font-size: 1rem;">
                            {{ poetrys[index + key].data.original }}
                        </div>
                    </div>
                </div>
            </swiper-slide>


        </swiper>
    </div>
</template>
    
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cards';
import axios from 'axios'

import { EffectCards } from 'swiper';
export default {

    name: 'PoetryBody',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        currentProp: {
            type: Object,
        },
    },
    data: function () {
        console.log(this.currentProp);
        return {
            modules: [EffectCards],
            poetrys: this.currentProp.data,
            index: this.currentProp.index,
            type: this.currentProp.type,
            listIndex: 0,
            page: this.currentProp.page,
            user: this.currentProp.user != undefined ? this.currentProp.user : "",
            bodyW: document.body.clientWidth
        };
    },
    methods: {
        switchPoetry: function (e) {

            let index = e.realIndex

            if (index == 0 && this.listIndex == 2) {
                if (this.index + 3 >= this.poetrys.length - 1) {
                    this.getData(1)
                } else {
                    this.index += 3
                }
            } else if (index == 2 && this.listIndex == 0) {
                if (this.index - 3 <= 0) {
                    this.getData(-1)
                } else {
                    this.index -= 3
                }
            }
            this.listIndex = index;
        },
        getData: function (x) {
            let _this = this;
            if (this.type == '固定顺序') {
                if ((x == -1 && this.page == 0) || (x == 1 && this.stop))
                    return
                let num;
                if (x == 1) {
                    num = (this.page + 1) * 10
                } else {
                    num = (this.page - 1) * 10
                }
                axios.post('/api/getArticleList', { "pageNum": num, "type": "诗歌", user: _this.user }).then(res => {
                    if (res.data.status == 'YES') {
                        _this.page += x;

                        if (x > 0) {
                            if (res.data.data == 0 || res.data.data == null)
                                return _this.stop = true
                            for (let i of res.data.data) {
                                _this.poetrys.push({ "data": i });
                            }
                            if (res.data.data.length < 10)
                                _this.stop = true
                        } else {
                            if (res.data.data == 0 || res.data.data == null)
                                return _this.page = 0
                            for (let i = res.data.data.length - 1; i >= 0; i--) {
                                _this.poetrys.unshift({ "data": res.data.data[i] });
                            }
                            _this.index += res.data.data.length
                        }
                        _this.index += x * 3
                    }
                })
            } else {
                axios.post('/api/getArticleRand').then(res => {
                    if (res.data.status == 'YES') {
                        _this.page += x;

                        if (x > 0) {
                            if (res.data.data == 0 || res.data.data == null)
                                return _this.stop = true
                            for (let i of res.data.data) {
                                _this.poetrys.push({ "data": i });
                            }
                            if (res.data.data.length < 10)
                                _this.stop = true
                        } else {
                            if (res.data.data == 0 || res.data.data == null)
                                return _this.page = 0
                            for (let i = res.data.data.length - 1; i >= 0; i--) {
                                _this.poetrys.unshift({ "data": res.data.data[i] });
                            }
                            _this.index += res.data.data.length
                        }
                        _this.index += x * 3
                    }
                })
            }
        }
    }


}

</script>


<style scoped>
.swiper-slide {
    display: flex;
    border-radius: 18px;
    color: #fff;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
    font-size: 22px;
}

.poetry-body {
    margin: 10px 40px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.poetry-body::-webkit-scrollbar {
    display: none;
}
</style>
    