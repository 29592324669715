<template>
    <div :style="{ backgroundColor: ['HomePage', 'TimeMachine', 'MyPoetryList', 'ArticleList', 'PoetryList'].includes(pageName) ? '#000000' : '#fff' }">
        <div style="max-width: 1920px;height: 100%;margin: 0 auto;">
            <MenuHome @callPage="callPage" :currentProp="pageData['MenuHome']" />
            <!-- <HomePage /> -->
            <component :is="pageName" @callPage="callPage" @setPage="setPage" :currentProp="pageData[pageName]"></component>
        </div>
        <VersionInformation @setPage="setPage" :currentProp="pageData['VersionInformation']"></VersionInformation>
    </div>
</template>

<script>
import MenuHome from './components/MenuHome.vue'
import HomePage from './view/HomePage.vue'
import AdminPage from './view/AdminPage.vue'
import UploadArticle from './view/UploadArticlePage.vue'
import PoetryList from './view/PoetryList.vue'
import MyPoetryList from './view/MyPoetryList.vue'
import PoetryBody from './view/PoetryBody.vue'
import ArticleList from './view/ArticleList.vue'
import ArticleBody from './view/ArticleBody.vue'
import TimeMachine from './view/TimeMachine.vue'
import VersionInformation from './components/VersionInformation.vue'
import ReviseArticle from './view/ReviseArticlePage.vue'
import { Modal } from '@arco-design/web-vue';

export default {
    name: 'Index',
    data() {
        return {
            "pageName": 'HomePage',
            "pageData": {
                "HomePage": { VideoFrams: {} }, "AdminPage": {}, "UploadArticle": {}, "PoetryList": { page: 0 },
                "MyPoetryList": { page: 0 }, "PoetryBody": {}, "ArticleList": { page: 0 },
                "MenuHome": { "color_0": '#ff0022', 'color_1': '#000', 'color_2': '#fff' },
                "TimeMachine": {}, "VersionInformation": { "6": 0 }, "ReviseArticle": {}
            },
            "pageArg": {}
        }
    },
    components: {
        MenuHome,
        HomePage,
        AdminPage,
        UploadArticle,
        PoetryList,
        PoetryBody,
        ArticleList,
        ArticleBody,
        MyPoetryList,
        TimeMachine,
        VersionInformation,
        ReviseArticle
    },
    methods: {
        callPage: function (value) {
            let page_name;
            if (value instanceof Object) {
                page_name = value.page;
                let page_data = value.data;
                this.pageData[page_name] = page_data;
            } else {
                page_name = value;
            }

            let _this = this
            if (this.pageName == page_name)
                return
            if (this.pageData[this.pageName].switch == 'check') {
                const handleClick = () => {
                    Modal.warning({
                        title: '页面跳转',
                        content: () => this.pageData[this.pageName].msg,
                        hideCancel: false,
                        onBeforeOk: function () {
                            _this.pageData['MenuHome'] = { "color_0": '#ff0022', 'color_1': '#000', 'color_2': '#fff' }
                            _this.pageData['VersionInformation']['6'] = 1
                            setTimeout(() => {
                                _this.pageName = page_name
                                document.getElementsByTagName('body')[0].click()
                            }, 1000)

                            return true
                        }
                    });
                };
                handleClick()
            } else {
                this.pageData['MenuHome'] = { "color_0": '#ff0022', 'color_1': '#000', 'color_2': '#fff' }
                if (['PoetryList', 'ArticleList', 'MyPoetryList', 'TimeMachine'].includes(page_name)) {
                    _this.pageData['VersionInformation']['6'] = 1
                    setTimeout(() => {
                        _this.pageName = page_name
                        document.getElementsByTagName('body')[0].click()
                    }, 1000)
                } else {
                    _this.pageName = page_name
                }

            }

        },
        setPage: function (value) {
            let page_name = this.pageName;
            if (value.page_name) {
                page_name = value.page_name;
                delete value.page_name;
            }
            this.pageData[page_name] = value;

        }
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.demoooo {
    max-width: 20% !important;
    color: antiquewhite;
    bottom: 1;
}
</style>
