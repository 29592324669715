<template>
    <div style="width: 100%;height: 100vh; padding: 5px;
                                                    background: url(/static/dist/static/img/PANTHER.jpg) no-repeat center center;
                                                    background-color: black; 
                                                    background-size:100% auto;
                                                    overflow-x: hidden;
                                                    /* overflow-y: scroll; */
                                                                                                                    ">

        <div style="padding: 140px 0px 50px 0px;background-size:100% auto;display: flex;justify-content: center;">

            <div :style="{ display: poetryCardDisplay }">
                <a-card hoverable :style="{ width: '260px', marginTop: '15px', borderRadius: '10px' }">
                    <template #cover>

                        <div :style="{
                            height: '99px',
                            overflow: 'hidden',
                            width: '99px',
                            margin: '20px auto'
                        }">
                            <a-avatar :size="99" style="margin: auto;" image-url="/static/dist/static/img/mn.jpeg"
                                id="avatar"></a-avatar>
                        </div>
                    </template>
                    <a-card-meta title="一只大乌龟" style="text-align: center;">
                        <template #description>
                            <br />
                            <div
                                style="font-size: 2rem;width: 60%;display: flex;justify-content: space-around;margin: auto;">
                                <a href=" https://www.douban.com/people/64401447/" id="avatar">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-shejiaotubiao-48"></use>
                                    </svg></a>
                                <a href="https://steamcommunity.com/profiles/76561198190266386/" id="avatar">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-steam"></use>
                                    </svg>
                                </a>
                                <a href="" id="avatar">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-gongzhonghao"></use>
                                    </svg>
                                </a>
                            </div>
                            <div style="margin: 10px;">
                                “人の世や<br>木の葉かくさㇸ<br>叱らるる”--小林一茶<br><br>
                                魂系游戏爱好者<br>京都动画爱好者<br>一个脱离了高级趣味的人
                            </div>
                        </template>
                    </a-card-meta>
                </a-card>


                <a-card hoverable :style="{ width: '260px', marginTop: '25px', borderRadius: '10px' }">
                    <template #cover>
                        <div style="line-height: 20px;margin: 20px 0px;text-align: center;">
                            “他停在我身上，像一头大象”<br>
                            另一个比方是<br>
                            在苹果的气候里，小于你<br>
                            可以是一种习惯<br>
                            没有另外的边疆<br>
                            以至于爱竟揭竿而起<br>
                            日历上连续绽开的<br>
                            诡辩<br>
                            像鼓掌<br>

                        </div>
                        <div style="margin:0px 70px 20px 0px;text-align: right;width: 200px;">2021.11</div>
                    </template>

                </a-card>
            </div>

            <div :style="{ width: poetrtListWidth, maxWidth: '850px' }">

                <div class="poetry-list">
                    <div :style="{ maxWidth: '360px', width: '100%', margin: bodyW >= 730 ? '0PX  2%' : '0PX  auto' }">
                        <div v-for="(value, key) in Poetrys">
                            <div v-if="key % 2 == 0" style="width: 100%; marginTop: 4%">
                                <a-card :style="{ width: '100%', margin: '5px auto 0px auto' }" :title="value.data.title"
                                    hoverable>
                                    <template #extra>
                                        <a-link @click="showPoetry(key)">查看</a-link>
                                    </template>
                                    <a-collapse :key="current">
                                        <a-collapse-item
                                            :header="value.switch ? value.title : value.title + '\r\n' + value.data.body"
                                            @click="switchPoetryTitle(value)" :style="value.style" :bordered="false"
                                            :show-expand-icon="false">
                                            <div style="white-space: pre-wrap;">{{ value.data.body }}</div>
                                        </a-collapse-item>
                                    </a-collapse>
                                </a-card>
                            </div>
                        </div>
                    </div>

                    <div :style="{ maxWidth: '360px', width: '100%', margin: bodyW >= 730 ? '0PX  2%' : '0PX  auto' }">
                        <div v-for="(value, key) in Poetrys">
                            <div v-if="key % 2 == 1" :style="{ maxWidth: '360px', width: '100%', marginTop: '4%' }">
                                <a-card :style="{ width: '100%', margin: '5px auto 0px auto' }" :title="value.data.title"
                                    hoverable>
                                    <template #extra>
                                        <a-link @click="showPoetry(key)">查看</a-link>
                                    </template>
                                    <a-collapse :key="current">
                                        <a-collapse-item
                                            :header="value.switch ? value.title : value.title + '\r\n' + value.data.body"
                                            @click="switchPoetryTitle(value)" :style="value.style" :bordered="false"
                                            :show-expand-icon="false">
                                            <div style="white-space: pre-wrap;">{{ value.data.body }}</div>
                                        </a-collapse-item>
                                    </a-collapse>
                                </a-card>
                            </div>
                        </div>
                    </div>
                </div>

                <a-pagination @change="switchPage" :total="count" :page-size="10" :current="current"
                    :style="{ margin: '20px auto 0px auto', width: '400px', maxWidth: '100%' }" :size="'small'" />
            </div>



        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'MyPoetryList',
    data() {
        console.log(this.currentProp);
        this.bodyW = document.body.clientWidth * 1
        let page = this.currentProp.page;
        let count = this.currentProp.count;
        let Poetrys = this.currentProp.Poetrys;
        this.poetrtListWidth = '70%'
        this.poetryCardDisplay = 'block'
        if (this.bodyW < 500) {
            this.poetrtListWidth = '100%'
            this.poetryCardDisplay = 'none'
        }
        if (Poetrys == undefined) {
            this.current = 1;
            this.getPageData(0)
        }
        else {
            console.log(count, page, Poetrys);
            this.count = count
            this.current = page
            this.Poetrys = Poetrys
        }
    },
    props: {
        currentProp: {
            type: Object,
        },
    },
    emits: ["setPage", "callPage"],
    methods: {
        switchPoetryTitle: function (value) {
            if (value.switch == undefined) {
                value.switch = true;
            } else if (value.switch == true) {
                value.switch = undefined
            }
        },
        switchPage: function (current) {
            this.current = current;
            this.getPageData((current - 1) * 10)
        },
        getPageData: function (num) {
            let _this = this;
            if (num == undefined)
                num = 0
            axios.post('/api/getArticleList', { "pageNum": num, "type": "诗歌", "user": "一只大乌龟" }).then(res => {

                if (res.data && res.data.status == 'YES') {

                    if (res.data.count)
                        _this.count = res.data.count * 1;
                    let poes = [];
                    for (let p of res.data.data) {
                        let poe = {
                            title: (p.writing_date == '' ? p.upload_date.slice(0, 11) : p.writing_date.slice(0, 11)),
                            data: p,
                            style: {
                                textOverflow: ' -o-ellipsis-lastline',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                '-webkit-line-clamp': 2,
                                lineClamp: 2,
                                '-webkit-box-orient': 'vertical',
                                whiteSpace: 'pre-wrap',
                                fontSize: '34px',
                                lineHeight: '32px',
                                letterSpacing: '2px',
                                // paddingBottom: '10px'
                            }
                        }
                        poes.push(poe);
                    }
                    _this.Poetrys = poes;
                    // 每次切换页面通知全局组件页面数据，在退出组件页面后再进入时保留当前页面
                    _this.$emit('setPage', { "page": _this.current, "count": _this.count, "Poetrys": _this.Poetrys });
                }
            })
        },
        showPoetry: function (index) {
            this.$emit('callPage', { "page": 'PoetryBody', "data": { data: JSON.parse(JSON.stringify(this.Poetrys)), index: index, page: this.current, type: '固定顺序', user: '一只大乌龟' } });
        }
    }
}


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 730px) {
    .poetry-list {
        /* display: flex;
        justify-content: center; */
        margin: 0px auto;
        width: 100%
    }
}

@media (min-width: 730px) {
    .poetry-list {
        display: flex;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }
}

.arco-pagination-list {
    margin: auto !important;
    width: 300px;
}

.arco-pagination {
    justify-content: center;
}
</style>
