<template>
    <div style="width: 100%;height: 100vh; padding: 5px;
                        background: url(/static/dist/static/img/FOX_2.jpg) no-repeat center center;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: black; 
                        background-size:auto 100% ;
                        overflow-x: hidden;
                        overflow-y: scroll;
                                                                                        ">
        <div style="display: flex;width: 100%;justify-content: center;padding: 140px 0px 50px 0px;">



            <div style="background-size:100% auto;max-width: 90%;">

                <div class="poetry-list">
                    <div
                        :style="{ width: '700px', maxWidth: '98%', margin: '0PX  auto', marginRight: poetryListMarginRight }">
                        <div v-for="(value, key) in Poetrys">
                            <div style="width: 100%; marginTop: 4%;">
                                <a-card :style="{
                                        width: '100%', margin: '5px auto 0px auto', height: card_height, textOverflow: ' -o-ellipsis-lastline',
                                        overflow: 'hidden',
                                        borderRadius: '10px',
                                        display: '-webkit-box',
                                        '-webkit-line-clamp': 2,
                                        lineClamp: 2,
                                        '-webkit-box-orient': 'vertical',
                                        whiteSpace: 'pre-wrap',
                                        fontSize: '34px',
                                        lineHeight: '32px',
                                        letterSpacing: '2px',
                                        padding: '10px',
                                        cursor: 'pointer'
                                    }" @click="showArticle(key)" class="articleCord" :title="value.data.title"
                                    hoverable>
                                    <div :style="{ 'display': display, 'height': 'calc(100% - 70px)' }">
                                        <div
                                            :style="{ width: img_width, height: '100%', margin: '5px', verticalAlign: 'middle', display: 'table-cell', textAlign: 'center', overflow: 'hidden' }">
                                            <img :style="{ width: '100%', height: imgHeigjt, objectFit: 'cover', transition: 'all 0.5s' }"
                                                alt="dessert" id="ArticleListimg"
                                                :src="(value.data.cover == '' ? 'static/upload/3984103dee629b289b147126b215d019.jpg' : value.data.cover)" />
                                        </div>
                                        <mavon-editor v-model="value.data.body" :editable="false" :defaultOpen="'preview'"
                                            :subfield="false" :toolbarsFlag="false"
                                            style="width: 100%;height: 100%;margin: 5px auto 10px auto;z-index: 2;"
                                            :externalLink="externalLink" class="_editor" 
                                            :scrollStyle="false"/>
                                    </div>
                                </a-card>

                            </div>
                        </div>
                    </div>
                </div>

                <a-pagination @change="switchPage" :total="count" :page-size="10" :current="current"
                    :style="{ margin: '20px auto 0px auto', width: '400px', maxWidth: '100%' }" :size="'small'" />
            </div>

            <div :style="{ display: RightDisplay }">

                <a-card hoverable :style="{ width: '300px', marginTop: '25px', borderRadius: '10px' }">
                    <template #cover>

                        <div :style="{
                                height: '99px',
                                overflow: 'hidden',
                                width: '99px',
                                margin: '20px auto'
                            }">
                            <a-avatar :size="99" style="margin: auto;" image-url="/static/dist/static/img/mn.jpeg"
                                id="avatar"></a-avatar>
                        </div>
                    </template>
                    <a-card-meta title="一只大乌龟" style="text-align: center;">
                        <template #description>
                            <br />
                            <div
                                style="font-size: 2rem;width: 60%;display: flex;justify-content: space-around;margin: auto;">
                                <a href=" https://www.douban.com/people/64401447/" id="avatar">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-shejiaotubiao-48"></use>
                                    </svg></a>
                                <a href="https://steamcommunity.com/profiles/76561198190266386/" id="avatar">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-steam"></use>
                                    </svg>
                                </a>
                                <a href="" id="avatar">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-gongzhonghao"></use>
                                    </svg>
                                </a>
                            </div>
                            <div style="margin: 20px 10px;">
                                “爱是一种用自己发行的货币偿还债务的激情”--W.G.塞巴尔德
                            </div>
                        </template>
                    </a-card-meta>
                </a-card>


                <a-card hoverable :style="{ width: '300px', marginTop: '25px', borderRadius: '10px' }">
                    <template #cover>
                        <div style="line-height: 20px;margin: 20px 0px;text-align: center;">
                            <p5-title content="关于本站" size="large" font_color="#ff0022" selected_font_color="#000"
                                selected_bg_color="#ff0022"></p5-title><br><br>
                            <div style="line-height: 25px;"
                                size="small">摸鱼时间搭建的，也不全是一时兴起：<br>一是因为五月底QQ账户被禁用，
                                <br>多年记录付之东流；<br>二是近年来不断紧缩的言论尺度之下，
                                <br>越发感到自己是被简中互联网驱逐的一批人。<br>
                                有没有人看到无所谓，能随意说话就足够了。<br><br>


                                后端框架基于c++的TinyWebServer<br>
                                实际使用时还是太简陋了，不太推荐<br>
                                前端框架采用了基于VUE3的p5-ui以及arco-design<br>
                                markdown编辑器：mavon-editor

                            </div>
                        </div>
                        <div style="margin:0px 20px 20px 0px;text-align: right;width: 280px;"><p5-text
                                size="small">2020.11</p5-text></div>
                    </template>

                </a-card>

            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ArticleList',
    data() {
        this.bodyW = document.body.clientWidth * 1
        this.display = 'flex';
        this.img_width = '40%'
        this.img_height = '100%'
        this.card_height = '300px'
        this.poetryListMarginRight = '40px'
        this.RightDisplay = 'block'
        this.imgHeigjt = '100%'
        if (this.bodyW < 500) {
            this.display = 'block';
            this.img_width = '100%'
            this.card_height = '500px'
            this.poetryListMarginRight = '0px'
            this.RightDisplay = 'none'
            this.imgHeigjt = ''
        }


        let page = this.currentProp.page;
        let count = this.currentProp.count;
        let Poetrys = this.currentProp.Poetrys;
        console.log(Poetrys);
        if (Poetrys == undefined) {
            this.current = 1;
            this.getPageData(0)
        }
        else {
            console.log(count, page, Poetrys);
            this.count = count
            this.current = page
            this.Poetrys = Poetrys
        }
        return {
            externalLink: {
                markdown_css: function () {
                    // 这是你的markdown css文件路径
                    return '/markdown/github-markdown.min.css';
                },
                hljs_js: function () {
                    // 这是你的hljs文件路径
                    return '/markdown/highlight.min.js';
                },
                // hljs_css: function (css) {
                //     // 这是你的代码高亮配色文件路径
                //     return '/highlightjs/styles/' + css + '.min.css';
                // },
                // hljs_lang: function (lang) {
                //     // 这是你的代码高亮语言解析路径
                //     return '/highlightjs/languages/' + lang + '.min.js';
                // },
                katex_css: function () {
                    // 这是你的katex配色方案路径路径
                    return '/markdown/katex.min.css';
                },
                katex_js: function () {
                    // 这是你的katex.js路径
                    return '/markdown/katex.min.js';
                },
            }
        }
    },
    props: {
        currentProp: {
            type: Object,
        },
    },
    emits: ["setPage", "callPage"],
    methods: {
        switchPoetryTitle: function (value) {
            if (value.switch == undefined) {
                value.switch = true;
            } else if (value.switch == true) {
                value.switch = undefined
            }
        },
        switchPage: function (current) {
            this.current = current;
            this.getPageData((current - 1) * 10)
        },
        getPageData: function (num) {
            let _this = this;
            if (num == undefined)
                num = 0
            axios.post('/api/getArticleList', { "pageNum": num, "type": "文章" }).then(res => {
                if (res.data && res.data.status == 'YES') {
                    if (res.data.count)
                        _this.count = res.data.count * 1;
                    let poes = [];
                    for (let p of res.data.data) {
                        let poe = {
                            title: (p.chinese_name == '' ? p.name : p.chinese_name) + (p.translator_name != '' ? ' / ' + p.translator_name : ''),
                            data: p,
                            style: {
                                textOverflow: ' -o-ellipsis-lastline',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                '-webkit-line-clamp': 2,
                                lineClamp: 2,
                                '-webkit-box-orient': 'vertical',
                                whiteSpace: 'pre-wrap',
                                fontSize: '34px',
                                lineHeight: '32px',
                                letterSpacing: '2px',
                                paddingBottom: '10px'
                            }
                        }
                        poes.push(poe);
                    }
                    _this.Poetrys = poes;
                    // 每次切换页面通知全局组件页面数据，在退出组件页面后再进入时保留当前页面
                    _this.$emit('setPage', { "page": _this.current, "count": _this.count, "Poetrys": _this.Poetrys });
                }
            })
        },
        showArticle: function (index) {
            this.$emit('callPage', { "page": 'ArticleBody', "data": { data: this.Poetrys[index], page: this.current } });
        }
    },
    mounted() {
        this.$emit('setPage', { "page_name": 'MenuHome', "color_0": '#000', 'color_1': '#ff0022', 'color_2': '#ff0022' });
    }
}


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 730px) {
    .poetry-list {
        /* display: flex;
        justify-content: center; */
        margin: 0px auto;
        width: 100%
    }
}

@media (min-width: 730px) {
    .poetry-list {
        display: flex;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }
}

.arco-pagination-list {
    margin: auto !important;
    width: 300px;
}

.arco-pagination {
    justify-content: center;
}

#ArticleListimg:hover {
    transform: scale(2.3);
}

#avatar {
    transition: all 0.5s;
}

#avatar:hover {
    transform: scale(1.3);
}

._editor:hover {
    background-color: rgb(9, 128, 233);
}
</style>
