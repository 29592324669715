<template>
    <div style="width: 100%;height: 100vh;">
        <div ref="backImg" class="back-image" style="background-size: 100% auto;background-color: #0a0000;"></div>
        <div :style="{ display: imgDisplay }"
            style="background: url(/static/img/queen.png) no-repeat center center;background-size: 100% auto;position: fixed;top: 0px;left: 0px;width: 100%;height: 100vh;z-index: 5;">
        </div>
        <div style="width: 100%;height: 100vh;background-color: rgb(0, 0, 0);position: fixed;max-width: 1920px;">
            <div :style="{ margin: '15vh' + (bodyW < 700 ? '' : ' 0px') }"
                style="width: 100%;text-align: center;font-family:SimHei;white-space: nowrap;">
                <div style="font-size: 35vh;color: #2e2e2e;line-height: 35vh;">
                    第三
                </div>
                <div style="font-size: 35vh;color: #2e2e2e;line-height: 35vh;">
                    旋臂
                </div>

            </div>
            <div style="color: #272727;position: fixed;bottom: 10vh;width: 100%;text-align: center;max-width: 1920px;">枕边的宇宙微甜<br>而星星花朵依次绽放
            </div>
        </div>
        <div style="width: 100%;height: 100vh;padding: min(5vw, 5vh);z-index: 99;">

            <div style="width: 100%;height: 100%;margin: auto;z-index: 0;position:relative">
            </div>
        </div>
        <div style="max-width: 1920px;margin: auto;width: 100%;height: 100%;position: fixed;top:0px;z-index: 3;">
            <div ref="poetry-text-1" class="show-index-text-1">
                <div v-for="(value) in poetry[0]">
                    <p5-title :content=value.txt size="large" :animation="true" font_color="#ff0022"
                        selected_font_color="#000" selected_bg_color="#ff0022" :style="{ visibility: value.show }">
                    </p5-title>
                </div>
            </div>
            <div ref="poetry-text-2" class="show-index-text-2">
                <div v-for="(value) in poetry[1]">
                    <p5-title :content=value.txt size="large" :animation="true" font_color="#ff0022"
                        selected_font_color="#000" selected_bg_color="#ff0022" :style="{ visibility: value.show }">
                    </p5-title>
                </div>
            </div>
        </div>
        <canvas ref="can" style="display: none;"></canvas>
        <video ref="video" src="/static/video/Transition264.mp4" controls="true" muted style="display: none;" />
        <canvas ref="canvasVideo" style="display: block;position: fixed;top: 0%;z-index: -1;position:fixed;"></canvas>
    </div>
</template>

<script>
// import MenuHome from './MenuHome.vue'
import axios from 'axios'
import JSZip from "jszip"
import { P5Notification } from 'p5-ui'
import GoDB from 'godb';

export default {
    name: 'HomePage',
    data: function () {
        let _this = this;
        return {
            deltaYTime: new Date(),
            deltaYValue: 0,
            play: false,
            videoTime: 0,
            videoFrame: 0,
            videoMaxFrame: 221,
            showimg: true,
            AnimationqQueue: [],
            VideoFrams: this.currentProp.VideoFrams,
            VideoFramsStatus: false,
            Release: 1,
            hideImg: false,
            startY: 0,
            showTitle: 0,
            canplay: false,
            backImage: undefined,
            poetry: {},
            status: false,
            imgDisplay: 'none',
            bodyW: document.body.clientWidth,
            listenerTouchmoveFunc: function (evt) { // 禁止微信浏览器拖动
                evt.preventDefault()
                //获取滑动屏幕时的Y
                let endY = evt.changedTouches[0].pageY;
                //获取滑动距离
                let distanceY = endY - _this.startY;
                //判断滑动方向
                if (distanceY < 0) {
                    // console.log(distanceY);
                    _this.addAnimationqframe(-500);
                } else if (distanceY > 0) {
                    // console.log(distanceY);
                    _this.addAnimationqframe(500);
                } else {
                    _this.AnimationqQueue = [];
                    console.log('点击未滑动');
                }
            },

            listenerTouchstartFunc: function (e) {
                _this.startY = e.changedTouches[0].pageY;
            }
        }
    },

    props: {
        currentProp: {
            type: Object,
        },
    },

    emits: ["setPage"],

    methods: {
        // 滚轮响应函数
        handleScroll: function (e) {

            let direction = e.deltaY
            this.addAnimationqframe(direction > 0 ? 200 : -200)

        },

        addAnimationqframe: function (direction) {
            // 开场图片未退场时不播放
            if (this.showimg && direction < 0)
                return
            let _this = this
            let handleScrolltime = new Date();

            this.deltaYValue += direction;
            if (handleScrolltime - this.deltaYTime > 1000) {
                this.deltaYValue = 0;
            } else {
                let Release = direction > 0 ? 1 : -1;
                // 触发放行相反则清空帧队列
                if (Release != this.Release) {
                    this.Release = Release;
                    this.AnimationqQueue = [];
                    direction = Release * 1000;
                }
            }
            this.deltaYTime = handleScrolltime;
            if (Math.abs(this.deltaYValue) >= 1000 || this.play == true) {
                this.play = true
                setTimeout(function () {
                    if (new Date() - _this.deltaYTime > 1000)
                        _this.play = false
                }, 2000)

                // 最多存储50帧
                if (this.VideoFramsStatus && this.AnimationqQueue.length < 50) {
                    if (_this.showimg && _this.canplay) {
                        _this.showImg()
                    }
                    // this.AnimationqQueue.push(direction)

                    if (Math.abs(direction) >= 100) {
                        // 开始时多存储4帧
                        if (this.AnimationqQueue.length == 0) {
                            this.AnimationqQueue.push(direction)
                            this.AnimationqQueue.push(direction)
                            this.AnimationqQueue.push(direction)
                            this.AnimationqQueue.push(direction)
                        }
                        this.AnimationqQueue.push(direction)
                    } else {
                        this.videoTime += direction;
                        if (Math.abs(this.videoTime) > 100) {
                            this.AnimationqQueue.push(this.videoTime);
                            this.videoTime = 0;
                        }
                    }

                }

            }
        },

        showImg: function () {

            // this.$refs.backImg.classList.value = 'magictime spaceOutRight';
            this.showimg = false;
            delP5UIMsg()
            this.run(1)
            // setTimeout(function () {
            //     _this.hideImg = true;
            //     _this.$refs.canvasVideo.style.zIndex = -1
            // }, 300)

        },

        showText: function (videoFrame, direction) {
            let allText = {};
            for (let i in this.poetry) {
                for (let j in this.poetry[i])
                    allText[j] = i
            }
            if ((direction > 0 && allText[videoFrame]) || (direction <= 0 && allText[videoFrame - 20])) {
                if (direction < 0)
                    videoFrame = videoFrame - 20
                this.poetry[allText[videoFrame]][videoFrame].show = 'visible';
            }

            else if ((direction < 0 && allText[videoFrame]) || (direction >= 0 && allText[videoFrame - 20]) || (direction < 0 && allText[videoFrame + 1])) {
                if (direction > 0)
                    videoFrame = videoFrame - 20
                if (direction < 0 && allText[videoFrame + 1])
                    videoFrame += 1
                this.poetry[allText[videoFrame]][videoFrame].show = 'hidden';
            }
        },

        // 绘制视频帧
        drawVideoFrame: function (x) {

            if ((this.videoFrame == 222 && x > 0) || (this.videoFrame <= 1 && x < 0)) {
                if (this.videoFrame <= 1 && x < 0) {
                    this.showTitle += 1;
                    if (this.showTitle > 5) {
                        this.play = false
                        this.videoTime = 0
                        this.videoFrame = 0

                        this.showimg = true
                        this.AnimationqQueue = []

                        this.Release = 1
                        this.hideImg = false
                        this.startY = 0
                        this.showTitle = 0
                        this.run(0)
                    }
                }
                return
            }

            this.showTitle = 0;
            this.videoFrame += x
            this.showText(this.videoFrame, x);
            let frameImg = this.VideoFrams[this.videoFrame];

            let _this = this;

            let canvas = this.$refs.canvasVideo;
            let bodyWidth = this.$refs.backImg.clientWidth
            let bodyHeight = this.$refs.backImg.clientHeight

            let ctx = canvas.getContext('2d', { willReadFrequently: true })
            // console.log(this.videoFrame);
            // console.log(frameImg);
            function draw() {
                ctx.drawImage(
                    frameImg,
                    _this.imgRect.sx,
                    _this.imgRect.sy,
                    _this.imgRect.sWidth,
                    _this.imgRect.sHeight,
                    0,
                    0,
                    bodyWidth,
                    bodyHeight
                )
                // setTimeout(draw, 1);
            }

            draw()

        },


        playBackAnimation: function () {

            let _this = this;
            let play = function () {
                if (_this.AnimationqQueue.length > 1 && _this.hideImg) {
                    if (Math.abs(_this.AnimationqQueue[0] / 100) >= 1) {
                        let t = _this.AnimationqQueue.shift();
                        let frame_len = Math.round(t / 100);
                        let r = frame_len > 0 ? 1 : -1;
                        for (let i = 0; i < Math.abs(frame_len); i++) {
                            setTimeout(function () {
                                _this.drawVideoFrame(r);
                            }, 50 * i);
                        }
                        setTimeout(play, Math.abs(frame_len) * 50);
                    } else {
                        setTimeout(play, 50)
                    }
                } else {
                    setTimeout(play, 50)
                }
            }
            play();

        },

        /**
         * @param {Number} box_w 固定盒子的宽, box_h 固定盒子的高
         * @param {Number} source_w 原图片的宽, source_h 原图片的高
         * @return {Object} {截取的图片信息}，对应drawImage(imageResource, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)参数
         */
        coverImg: function (box_w, box_h, source_w, source_h) {
            var sx = 0,
                sy = 0,
                sWidth = source_w,
                sHeight = source_h
            if (source_w > source_h || (source_w == source_h && box_w < box_h)) {
                sWidth = (box_w * sHeight) / box_h
                sx = (source_w - sWidth) / 2
            } else if (
                source_w < source_h ||
                (source_w == source_h && box_w > box_h)
            ) {
                sHeight = (box_h * sWidth) / box_w
                sy = (source_h - sHeight) / 2
            }

            return {
                sx,
                sy,
                sWidth,
                sHeight,
            }
        },

        run: function (type) {
            let _this = this
            let video = this.$refs.video
            // let TOLERANCE = 40

            // const rgb = [4, 127, 0]
            // const rgb2 = [153, 176, 157]

            function cutOut() {
                let frameData = ctx.getImageData(0, 0, bodyWidth, can.height),
                    len = frameData.data.length / 4

                for (let i = 0; i < len; i++) {

                    let r = frameData.data[i * 4 + 0]
                    let g = frameData.data[i * 4 + 1]
                    let b = frameData.data[i * 4 + 2]
                    if (
                        (
                            //     (
                            //     Math.abs(r - rgb[0]) <= TOLERANCE &&
                            //     Math.abs(g - rgb[1]) <= TOLERANCE + 50 &&
                            //     Math.abs(b - rgb[2]) <= TOLERANCE
                            // ) || 
                            // (
                            //     Math.abs(r - rgb2[0]) <= TOLERANCE &&
                            //     Math.abs(g - rgb2[1]) <= TOLERANCE &&
                            //     Math.abs(b - rgb2[2]) <= TOLERANCE 
                            // ) || 
                            (g * 2 - (r + b) > 30) ||
                            // (    g / (r + b) > 0.7 && r + b > 0 || 
                            (r == 0 && b == 0 && g > 10)) &&
                        (g > 15)
                    ) {
                        frameData.data[i * 4 + 3] = 0
                    }
                }
                return frameData
            }

            let bodyWidth = document.body.clientWidth < 1920 ? document.body.clientWidth : 1920

            let bodyHeight = document.body.clientHeight


            let can = this.$refs.can
            if (this.can == undefined) {
                can.width = bodyWidth
                can.height = bodyWidth * (1080 / 1920)
                can.style = `height:${can.heigh}px;width:${(bodyWidth)}px;position:fixed;top:${(bodyHeight - can.height) / 2}px;`
                can.allowfullscreen = 'true'
                this.can = can
            }

            let ctx = can.getContext('2d', { willReadFrequently: true })


            // document.body.appendChild(can)
            //插入到最前面
            // document.body.insertBefore(can, document.body.firstElementChild)

            // let imgWidth = 2164
            // let imgHeight = 1018


            function draw() {
                ctx.drawImage(
                    video,
                    0,
                    0,
                    1920,
                    1080,
                    0,
                    0,
                    bodyWidth,
                    can.height
                )
                ctx.putImageData(cutOut(), 0, 0)
            }


            let remTime;
            let remCav = () => {
                // TOLERANCE = 40
                if (remTime - this.$refs.video.currentTime > 0 && type == 1) {
                    // TOLERANCE = 30
                    _this.hideImg = true;
                    _this.$refs.canvasVideo.style.zIndex = 1
                    _this.$refs.can.style.zIndex = 2
                }

                if (this.$refs.video.currentTime < remTime) {
                    setTimeout(remCav, 20)
                } else {
                    // can.remove()
                    _this.$refs.video.pause()
                    if (type == 1) {
                        _this.hideImg = true;
                        _this.$refs.canvasVideo.style.zIndex = 1
                        _this.$refs.can.style.zIndex = 0
                    } else {
                        // _this.$refs.can.style.zIndex = 1
                        _this.$refs.video.currentTime = 0.5;
                        setTimeout(draw, 1000)
                        _this.status = true;
                    }
                }
            }

            if (type == 1) {
                _this.$refs.video.currentTime = 0.5;
                remTime = 1;
                remCav()
                setTimeout(() => {
                    _this.$refs.can.style.zIndex = 1;
                    video.play()
                }, 200)
            } else {
                _this.$refs.video.currentTime = 0;
                remTime = 0.5;
                remCav()
                setTimeout(() => {
                    this.$refs.canvasVideo.style.zIndex = -1
                    video.play()
                }, 500)
            }

            let play = true
            video.addEventListener(
                'play',
                () => {
                    this.timer && clearInterval(this.timer)
                    this.timer = setInterval(() => {
                        draw()
                        if (play == false)
                            clearInterval(this.timer)
                    }, 50)
                },
                false
            )

            video.addEventListener(
                'ended',
                function () {
                    // can.remove()
                    play = false;
                },
                false
            )

        }


    },

    mounted() {
        let _this = this
        // 连接数据库
        const p5web = new GoDB('p5web');
        const videofBlob = p5web.table('videofBlob')

        let _start = function () {
            if (Object.keys(_this.VideoFrams).length > 221) {
                _this.VideoFramsStatus = true;


                //  * @param {Number} box_w 固定盒子的宽, box_h 固定盒子的高
                //  * @param {Number} source_w 原图片的宽, source_h 原图片的高
                //  * @return {Object} {截取的图片信息}，对应drawImage(imageResource, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)参数
                let coverImg = function (box_w, box_h, source_w, source_h) {
                    var sx = 0,
                        sy = 0,
                        sWidth = source_w,
                        sHeight = source_h
                    if (source_w > source_h || (source_w == source_h && box_w < box_h)) {
                        sWidth = (box_w * sHeight) / box_h
                        sx = (source_w - sWidth) / 2
                    } else if (
                        source_w < source_h ||
                        (source_w == source_h && box_w > box_h)
                    ) {
                        sHeight = (box_h * sWidth) / box_w
                        sy = (source_h - sHeight) / 2
                    }
                    return {
                        sx,
                        sy,
                        sWidth,
                        sHeight,
                    }
                }
                _this.imgRect = coverImg(
                    _this.$refs.backImg.clientWidth, _this.$refs.backImg.clientHeight,
                    1600, 900)
                _this.playBackAnimation();
                P5Notification({
                    content: '学长，滑动鼠标或屏幕试试呢', character: 'kasumi',
                    top: document.body.clientHeight * 0.2, left: (document.body.clientWidth <= 1920 ? document.body.clientWidth * 0.1 : (document.body.clientWidth - 1920) / 2 + 150)
                })
                console.log('_this.VideoFrams is Readyed');
            } else {
                setTimeout(_start, 50);
            }
        }

        if (Object.keys(_this.VideoFrams).length > 0) {
            _start();
        } else {

            videofBlob.findAll((item) => {
                return item.id >= 0;
            }).then(re => {

                if (re.length >= 222) {
                    let addImgs = function () {
                        for (let _img in re) {
                            let src = URL.createObjectURL(re[_img].blob)
                            let img = document.createElement('img')
                            img.src = src
                            _this.VideoFrams[re[_img].id] = img
                        }
                    }

                    _this.$emit('setPage', { "VideoFrams": _this.VideoFrams });

                    let _run = function () {
                        if (_this.status == true) {
                            addImgs();
                            _start()
                        } else {
                            setTimeout(_run, 50);
                        }
                    }

                    // 等待开场动画播放完成后再处理图像，否则可能浏览器卡顿
                    _run();

                    console.log("import images from database");
                } else {
                    axios.get('/api/static/file/P5OP.zip', {
                        responseType: 'blob'   //首先设置responseType字段格式为 blob
                    }).then(res => {
                        let blob = new Blob([res.data], { type: "application/zip" }) // 为blob设置文件类型

                        function useZip(blob, num) {

                            //因为压缩文件内一般有多个页文件，所以需要遍历有几个文件，获取文件名，分别解压缩
                            for (let i = 0; i < num; i++) {
                                let _n = i
                                var nameArr = []
                                try {
                                    JSZip.loadAsync(blob).then((zip) => {

                                        for (var key in zip.files) {
                                            nameArr.push(key)
                                        }
                                        let ret = zip.file(nameArr[i]).async('blob')

                                        return ret
                                    }).then((response) => {

                                        videofBlob.add({ "id": nameArr[_n].replace('.jpg', ''), "blob": response })
                                        let src = URL.createObjectURL(response)
                                        let img = document.createElement('img')
                                        img.src = src

                                        _this.VideoFrams[nameArr[_n].replace('.jpg', '')] = img

                                    })
                                } catch (e) {
                                    console.log(e)
                                }
                            }
                        }

                        let _run = function () {
                            if (_this.status == true) {
                                useZip(blob, 222, false);
                                _this.$emit('setPage', { "VideoFrams": _this.VideoFrams });
                                _start()
                            } else {
                                setTimeout(_run, 50);
                            }
                        }

                        // 等待开场动画播放完成后再处理图像，否则可能浏览器卡顿
                        _run();

                    });

                }
            })

        }

        axios.post('/api/getRandomPoetry', { "maxLine": 6 }).then(res => {
            if (res.data && res.data.status == 'YES') {
                let poes0 = res.data.data[0].body.split('\r\n')
                let poes1 = res.data.data[1].body.split('\r\n')
                let num0 = 25;
                _this.poetry[0] = {}
                for (let i = 0; i < poes0.length; i++) {

                    _this.poetry[0][num0 + i * 2] = { 'txt': poes0[i], 'show': 'hidden' }
                }
                let num1 = 80;
                _this.poetry[1] = {}
                for (let i = 0; i < poes1.length; i++) {
                    _this.poetry[1][num1 + i * 2] = { 'txt': poes1[i], 'show': 'hidden' }
                }
            }
        })
        window.addEventListener('mousewheel', this.handleScroll)
        this.$refs.canvasVideo.setAttribute('width', this.$refs.backImg.clientWidth);
        this.$refs.canvasVideo.setAttribute('height', this.$refs.backImg.clientHeight);


        if (/Android|iPhone|iPad|iPod/i.test(navigator.platform)) {
            // 当前设备是移动设备
            _this.imgDisplay = 'block';
        } else {
            this.$refs.video.addEventListener('canplay', function () {
                let bodyWidth = document.body.clientWidth
                let bodyHeight = document.body.clientHeight
                _this.videoWidth = this.videoWidth
                _this.videohHeight = this.videoHeight
                _this.imgRect1 = _this.coverImg(bodyWidth, bodyHeight, _this.videoWidth, _this.videohHeight)
                if (!_this.canplay) {
                    console.log(_this.canplay);
                    _this.run(0)
                }
                _this.canplay = true;
            })
        }
    },

    created() {

        document.body.addEventListener('touchstart', this.listenerTouchstartFunc);
        document.body.addEventListener('touchmove', this.listenerTouchmoveFunc,
            { passive: false })
    },

    beforeUnmount: function () {

        // 生命周期销毁
        this.deltaYTime = new Date()
        this.deltaYValue = 0
        this.play = false
        this.videoTime = 0
        this.videoFrame = 0
        this.videoMaxFrame = 221
        this.showimg = true
        this.AnimationqQueue = []
        this.VideoFrams = {}
        this.VideoFramsStatus = false
        this.Release = 1
        this.hideImg = false
        this.startY = 0
        this.showTitle = 0
        this.canplay = false
        this.backImage = undefined
        this.poetry = {}
        this.status = false

        delP5UIMsg();

        document.body.removeEventListener('touchmove', this.listenerTouchstartFunc)
        document.body.removeEventListener('touchmove', this.listenerTouchmoveFunc, { passive: false })
    }
}


</script>

<style scoped>
.back-image {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    position: relative;
    top: 0%;
    left: 0%;
    z-index: 0;
    float: left;
}

.demoooo {
    max-width: 20% !important;
    color: antiquewhite;
    bottom: 1;
    display: block;
}
</style>