<template>
    <div
        style="width: 100%;height: 100%;min-height: 100vh; color:#fff;background-color: #ffc25e;overflow-x:hidden;overflow-y:hidden;">
        <div style="width: 100%;max-width: 1920px;height: 400px;position:fixed;overflow: hidden">

            <img v-if="article.data.cover != ''" class="articleBodyImg"
                :style="{ width: '100%', height: '100%', objectFit: 'cover', transition: 'all 0.5s' }" alt="dessert"
                id="ArticleListimg" :src="article.data.cover" />

        </div>
        <div style="max-width:800px;margin:140px auto 50px;padding: 10px;height: 100%;">
            <!-- <H1 style="text-align:center;color: #fe9e9e;font-size: 2.3rem;">{{ article.data.title }}</H1> -->
            <div style="text-align:center;margin-bottom: 15px;"><p5-title :content="article.data.title" size="extra-large"
                    style="font-size: 2.3rem;"></p5-title></div>
            <mavon-editor v-model="article.data.body" :editable="false" :defaultOpen="'preview'" :subfield="false"
                :toolbarsFlag="false" style="width: 100%;height: 100%;margin: 5px auto 10px auto;z-index: 2;"
                :externalLink="externalLink" previewBackground="#ffd691" />
            <div style="text-align: right;margin-top: 20px;"><p5-title :content="article.data.username"
                    size="medium"></p5-title>&nbsp;&nbsp;
                <p5-title
                    :content="(article.data.writing_date == '' ? article.data.upload_date : article.data.writing_date).slice(0, 11)"
                    size="medium"></p5-title>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {

    name: 'ArticleBody',

    props: {
        currentProp: {
            type: Object,
        },
    },
    data: function () {
        console.log(this.currentProp);
        return {
            article: this.currentProp.data,
            page: this.currentProp.page,
            externalLink: {
                markdown_css: function () {
                    // 这是你的markdown css文件路径
                    return '/markdown/github-markdown.min.css';
                },
                hljs_js: function () {
                    // 这是你的hljs文件路径
                    return '/markdown/highlight.min.js';
                },
                // hljs_css: function (css) {
                //     // 这是你的代码高亮配色文件路径
                //     return '/highlightjs/styles/' + css + '.min.css';
                // },
                // hljs_lang: function (lang) {
                //     // 这是你的代码高亮语言解析路径
                //     return '/highlightjs/languages/' + lang + '.min.js';
                // },
                katex_css: function () {
                    // 这是你的katex配色方案路径路径
                    return '/markdown/katex.min.css';
                },
                katex_js: function () {
                    // 这是你的katex.js路径
                    return '/markdown/katex.min.js';
                },
            }
        };
    },
    methods: {}


}

</script>


<style scoped>
.swiper-slide {
    display: flex;
    border-radius: 18px;
    color: #fff;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
    font-size: 22px;
}

.poetry-body {
    margin: 10px 40px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.poetry-body::-webkit-scrollbar {
    display: none;
}

.articleBodyImg {
    filter: blur(3px) contrast(60%);
}

.articleBodyImg:hover{
    filter: blur(0px) contrast(80%);
    transform: scale(1.3);
}
</style>
    