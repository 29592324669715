<template>
  <div>
    <div>
      <div>
        <video ref="VersionInformationVideo" src="/static/video/6.mp4" controls="true" muted style="display: none" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'VersionInformation',
  data() {
    return {
      play: this.currentProp,
      caplay: { "6": false },
      imgRect: undefined
    }
  },
  mounted() {
    let video = this.$refs.VersionInformationVideo;
    let _this = this;
    _this.bodyWidth = window.innerWidth < 1920 ? window.innerWidth : 1920
    _this.bodyHeight = window.innerHeight
    video.addEventListener('canplay', function () {
      _this.videoWidth = this.videoWidth
      _this.videohHeight = this.videoHeight
      _this.imgRect = _this.coverImg(_this.bodyWidth, _this.bodyHeight, _this.videoWidth, _this.videohHeight)
    })
  },
  beforeUnmount() { },
  methods: {
    run: function (video, index) {

      /**
       * @param {Number} box_w 固定盒子的宽, box_h 固定盒子的高
       * @param {Number} source_w 原图片的宽, source_h 原图片的高
       * @return {Object} {截取的图片信息}，对应drawImage(imageResource, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)参数
       */

      let _this = this
      function cutOut() {
        let frameData = ctx.getImageData(0, 0, _this.bodyWidth, _this.bodyHeight),
          len = frameData.data.length / 4

        for (let i = 0; i < len; i++) {
          let r = frameData.data[i * 4 + 0]
          let g = frameData.data[i * 4 + 1]
          let b = frameData.data[i * 4 + 2]
          if (r + g + 50 < b) {
            frameData.data[i * 4 + 3] = 0
          }
        }
        return frameData
      }

      let bodyWidth = window.innerWidth < 1920 ? window.innerWidth : 1920
      let bodyHeight = window.innerHeight
      let can = document.createElement('canvas')

      can.width = bodyWidth
      can.height = bodyHeight
      can.style = `height:${bodyHeight}px;width:${(bodyWidth)}px;position:fixed;z-index:1099;
        top: 50%;left: 50%;transform: translate(-50%, -50%);
        
        `
      can.allowfullscreen = 'true'



      let ctx = can.getContext('2d', { willReadFrequently: true })
      // can.width = document.body.clientWidth
      // can.height = document.body.clientHeight
      // can.id = 'canvasVideo'
      // can.allowfullscreen = 'true'
      // can.style = 'height:vh;width:100%;position:fixed;z-index:1099;top:0px;left:0px;'
      document.body.appendChild(can)
      //插入到最前面
      // document.body.insertBefore(can, document.body.firstElementChild)

      let play = true;
      function draw() {

        ctx.drawImage(
          video,
          _this.imgRect.sx,
          _this.imgRect.sy,
          _this.imgRect.sWidth,
          _this.imgRect.sHeight,
          0,
          0,
          bodyWidth,
          bodyHeight
        )
        ctx.putImageData(cutOut(), 0, 0)


      }
      video.play()

      video.addEventListener(
        'play',
        () => {
          this.timer && clearInterval(this.timer)
          this.timer = setInterval(() => {
            draw()
            if (play == false)
              clearInterval(this.timer)
          }, 50)
        },
        false
      )
      video.addEventListener(
        'ended',
        function () {
          can.remove()
          _this.play[index] = 0;
          play = false;
        },
        false
      )
      // draw();
    },


    //  * @param {Number} box_w 固定盒子的宽, box_h 固定盒子的高
    //  * @param {Number} source_w 原图片的宽, source_h 原图片的高
    //  * @return {Object} {截取的图片信息}，对应drawImage(imageResource, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)参数
    coverImg: function (box_w, box_h, source_w, source_h) {
      var sx = 0,
        sy = 0,
        sWidth = source_w,
        sHeight = source_h
      if (source_w > source_h || (source_w == source_h && box_w < box_h)) {
        sWidth = (box_w * sHeight) / box_h
        sx = (source_w - sWidth) / 2
      } else if (
        source_w < source_h ||
        (source_w == source_h && box_w > box_h)
      ) {
        sHeight = (box_h * sWidth) / box_w
        sy = (source_h - sHeight) / 2
      }
      console.log(sx,
        sy,
        sWidth,
        sHeight,);

      if (box_w / box_h > source_w / source_h) {
        sx = 0
        sWidth = 1920
        sHeight = (box_h * sWidth) / box_w
        sy = (source_h - sHeight) / 2
      }
      // return {
      //   sx: 0, sy: 0, sWidth: 1920, sHeight: 1080
      // }
      return {
        sx,
        sy,
        sWidth,
        sHeight,
      }
    }
  },
  props: {
    currentProp: {
      type: Object,
    },
  },
  watch: {
    play: {
      handler(newValue, oldValue) {
        if (/Android|iPhone|iPad|iPod/i.test(navigator.platform)) {
          // 当前设备是移动设备
          return
        }
        console.log(newValue)
        for (let j in newValue) {
          if (newValue[j] == 1)
            this.run(this.$refs.VersionInformationVideo, j)
        }
        console.log(oldValue);
      },
      deep: true // 默认值是 false，代表是否深度监听
    }
  }
}
</script>
<style>
video::-webkit-media-controls {
  display: none !important;
}
</style>
