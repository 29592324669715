<template>
    <div style="width: 100%; background-color: #000000;overflow-x:hidden;overflow-y:hidden;">
        <div :style="{ transform: scale, transformOrigin: '0 0' }">
            <div style="width:1200px;margin:140px auto 50px;padding: 10px;position:relative;">
                <canvas style="width:700px;margin-left: 250px;" :style="{ height: Remarks.length * 200 - 200 + 'px' }"
                    :height="Remarks.length * 200 - 200" width="700" id="canvas"></canvas>
                <div v-for="value, index in Remarks" :style="{
                        position: 'absolute', top: value.index.x + 'px', left: value.index.y + (index % 2 == 100 ? 0 : 170) + 'px',
                        width: '250px',
                        textAlign: index % 2 == 0 ? 'right' : 'left'
                    }">
                    <span style="color: aliceblue;">{{ value.datetime.slice(0, 11) }}</span>
                    <p5-text size="large">{{ value.remark }}</p5-text>

                </div>
            </div>
        </div>
        <div style="z-index: 1;" id="TimeMachineImg"
            :style="{ position: 'fixed', right: (bodyW <= 1920 ? 20 : (bodyW - 1920) / 2 + 20) + 'px' }">
            <img src="/static/dist/static/img/P4.webp" style="width: 300px;" @click="showMsg">
        </div>
    </div>
</template>
    
<script>
import axios from 'axios'
import { P5Notification } from 'p5-ui'

export default {

    name: 'TimeMachine',

    props: {
        currentProp: {
            type: Object,
        },
    },
    data: function () {
        this.bodyW = document.body.clientWidth * 1
        this.bodyH = document.body.clientHeight * 1
        let scale = 'scale(1)';
        if (this.bodyW < 1200) {
            scale = 'scale(' + this.bodyW / 1200 + ')'
        }


        return {
            "Remarks": [],
            "scale": scale
        };
    },
    methods: {
        showMsg: function () {
            delP5UIMsg()
            P5Notification({ content: Math.random() > 0.3 ? '我不是里中千枝' : '要说点什么吗？', character: 'makoto', top: '200', left: (this.bodyW <= 1920 ? this.bodyW * 0.1 : (this.bodyW - 1920) / 2 + 150) })
        }
    },
    mounted: function () {
        let _this = this;
        axios.post("/getRemarks").then(res => {

            if (res.data.status == "YES") {
                _this.Remarks = []
                for (let j = res.data.data.length - 1; j >= 0; j--) {
                    let r = res.data.data[j];
                    r.index = {}
                    _this.Remarks.push(r)
                }

                setTimeout(() => {
                    var canvas = document.getElementById('canvas');
                    if (canvas.getContext) {
                        var ctx = canvas.getContext('2d');

                        ctx.beginPath();

                        let last = { left_0: 0, left_1: 0, right_0: 0, right_1: 0, top_0: 0, top_1: 0, bottom_0: 0, bottom_1: 0 }
                        for (let i = 0; i < res.data.data.length; i++) {
                            let left_0 = 1
                            let left_1 = 0
                            let right_0 = 1
                            let right_1 = 1
                            while (left_0 - left_1 < 70 || right_0 - right_1 < 70) {
                                left_0 = 200 * Math.random();
                                left_1 = 200 * Math.random();
                                right_0 = 500 + 200 * Math.random();
                                right_1 = 500 + 200 * Math.random();
                            }

                            let top_0 = 1
                            let top_1 = 0
                            let bottom_0 = 1
                            let bottom_1 = 1
                            while (top_0 - top_1 < 40 || bottom_0 - bottom_1 > 40) {
                                top_0 = i * 200 + 20 + 50 * Math.random();
                                top_1 = i * 200 + 20 + 50 * Math.random();

                                bottom_0 = i * 200 + 130 + 50 * Math.random();
                                bottom_1 = i * 200 + 130 + 50 * Math.random();
                            }


                            if (i % 2 == 0) {
                                if (i == 0) {
                                    ctx.moveTo(left_0, top_0);
                                    ctx.lineTo(left_1, top_1);
                                    ctx.lineTo(right_0, bottom_0);
                                    ctx.lineTo(right_1, bottom_1);
                                    _this.Remarks[i].index = { 'x': top_1, 'y': left_1 }
                                } else {
                                    if (i != res.data.data.length - 1) {
                                        ctx.moveTo(last.left_0, last.top_0);
                                        ctx.lineTo(last.left_1, last.top_1);
                                        ctx.lineTo(right_0, bottom_0);
                                        ctx.lineTo(right_1, bottom_1);
                                    }
                                    _this.Remarks[i].index = { 'x': last.top_1, 'y': last.left_1 }
                                }
                            } else {
                                if (i != res.data.data.length - 1) {
                                    ctx.moveTo(left_0, top_0);
                                    ctx.lineTo(left_1, top_1);
                                    ctx.lineTo(last.right_0, last.bottom_0);
                                    ctx.lineTo(last.right_1, last.bottom_1);
                                }

                                _this.Remarks[i].index = { 'x': last.bottom_0, 'y': last.right_0 }
                            }

                            last = {
                                left_0: left_0, left_1: left_1, right_0: right_0, right_1: right_1,
                                top_0: top_0, top_1: top_1, bottom_0: bottom_0, bottom_1: bottom_1
                            }
                            console.log(last);

                            ctx.closePath();
                        }

                        ctx.fillStyle = "red";
                        ctx.fill();
                        console.log(res.data);
                    }
                }, 100)






            }
        })

    }

}

</script>


<style scoped>
#TimeMachineImg {
    filter: contrast(35%);
    cursor: pointer;
    transition: all 0.2s;
    bottom: -100px;
}

#TimeMachineImg:hover {
    filter: contrast(80%);
    bottom: -10px;
    transform: scale(1.3);
}
</style>
    