<template>
  <a-modal v-model:visible="visible" :closable="false" :footer="false" :modal-style="logInStyle">
    <div :style="logBodyStyle">
      <p5-title content="进入印象空间" size="extra-large" font_color="#fff" selected_font_color="#000"
        selected_bg_color="#fff"></p5-title>
      <div style="margin: 10px auto;">
        <a-space direction="vertical" size="large">
          <a-input :style="{ width: '320px', backgroundColor: '#ff0022', color: '#fff' }" placeholder="用户名" allow-clear
            :size="'mini'" v-model="username" name="mobile" autocomplete="off">
            <template #prefix>
              <p5-icon type='party' name="justine" />
            </template>
          </a-input>
          <a-input-password :style="{ width: '320px', backgroundColor: '#ff0022', color: '#fff' }" placeholder="密码"
            allow-clear :size="'mini'" v-model="password" name="mobile1" autocomplete="off">
            <template #prefix>
              <p5-icon type='party' name="ann" />
            </template>
          </a-input-password>
        </a-space>
      </div>
      <div style="padding-top: 7px;">
        <p5-icon type='btn' name="close" style="margin-right: 10px;" :onclick="() => { visible = false }" />
        <p5-icon type='btn' name="confirm" style="margin-left: 10px;" :onclick="logIn" />
      </div>
    </div>
  </a-modal>


  <div :style="{ position: 'fixed', 'z-index': 99, width: vertical?'auto':'100vw', margin: '0 auto', 'max-width': '1920px' }">
    <div :class="vertical ? 'menu-demo' : ''">
      <a-menu :mode="vertical ? 'pop' : 'horizontal'" class="transparent" style="margin: 2%;" showCollapseButton
      expand-icon-right="1" @collapse="collapse" :default-collapsed="vertical">

        <a-menu-item key="0" :style="{ padding:vertical?'12px': 0, marginRight: vertical ? '0px' : '8px' }" class="transparent"
          @click="switchPage('HomePage')">
          <template #icon v-if="showIcon"> <img :style="{
              width: '16px',
              height: '16px',
            }" src="/static/dist/static/img/PHANTOM.png" />
            </template>

          <img :style="{
              width: '80px',
              height: '80px',
            }" src="/static/dist/static/img/PHANTOM.png" />
        </a-menu-item>

        <a-menu-item key="1" class="transparent">
          <template #icon v-if="showIcon"><span class="iconfont icon-shiciguanli"></span></template>
          <p5-title content="诗歌" size="large" :animation="true" :font_color="currentProp.color_0"
            :selected_font_color="currentProp.color_1" :selected_bg_color="currentProp.color_0"
            @click="switchPage('MyPoetryList')"></p5-title>
        </a-menu-item>

        <a-menu-item key="4" class="transparent">
          <template #icon v-if="showIcon"><span class="iconfont icon-1"></span></template>
          <p5-title content="文章" size="large" :font_color="currentProp.color_2" :selected_font_color="currentProp.color_0"
            :selected_bg_color="currentProp.color_2" @click="switchPage('ArticleList')"></p5-title>
        </a-menu-item>

        <a-menu-item key="2" class="transparent">
          <template #icon v-if="showIcon"><span class="iconfont icon-yuedu"></span></template>
          <p5-title content="刷诗机" size="large" :font_color="currentProp.color_2"
            :selected_font_color="currentProp.color_0" @click="poetryBody"
            :selected_bg_color="currentProp.color_2"></p5-title>
        </a-menu-item>

        <a-menu-item key="3" class="transparent">
          <template #icon v-if="showIcon"><svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-nvhai"></use>
            </svg></template>
          <p5-title content="AI wife" size="large" :font_color="currentProp.color_2"
            :selected_font_color="currentProp.color_0" :onclick="showLogin"
            :selected_bg_color="currentProp.color_2"></p5-title>
        </a-menu-item>

        <a-menu-item key="5" class="transparent">
          <template #icon v-if="showIcon"><span class="iconfont icon-yuedu2"></span></template>
          <p5-title content="荐读" size="large" :font_color="currentProp.color_0" :selected_font_color="currentProp.color_1"
            @click="switchPage('PoetryList')" :selected_bg_color="currentProp.color_0"></p5-title>
        </a-menu-item>

        <a-menu-item key="7" class="transparent">
          <template #icon v-if="showIcon"><svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shijian"></use>
            </svg></template>
          <p5-title content="时光机" size="large" :font_color="currentProp.color_2"
            :selected_font_color="currentProp.color_0" @click="switchPage('TimeMachine')"
            :selected_bg_color="currentProp.color_2"></p5-title>
        </a-menu-item>

        <a-menu-item key="6" class="transparent">
          <template #icon v-if="showIcon"><span class="iconfont icon-zhuye1"></span></template>
          <p5-title content="印象空间" size="large" :animation="true" :font_color="currentProp.color_0"
            :selected_font_color="currentProp.color_1" :selected_bg_color="currentProp.color_0"
            :onclick="showLogin"></p5-title>
        </a-menu-item>

      </a-menu>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import Cookies from 'js-cookie'
import axios from 'axios'
import md5 from "js-md5"
import { P5Notification } from 'p5-ui'


export default {
  setup(props, ctx) {
    let logInStyle = {
      background: 'url(/static/dist/static/img/loginImg.jpg)  no-repeat',
      backgroundSize: '100% 100%',
      width: '900px',
      height: 900 * 0.33 + 'px'
    }
    let logBodyStyle = {
      width: '500px',
      marginLeft: '350px',
      textAlign: 'center'
    }
    if (document.body.clientWidth < 900) {
      logInStyle = {
        background: 'url(/static/dist/static/img/loginImg2.jpg)  no-repeat',
        backgroundSize: '100% 100%',
        maxWidth: '350px',
        maxHeight: '329px',
        width: document.body.clientWidth * 0.9 + 'px',
        height: document.body.clientWidth * 0.9 * 0.94 + 'px',
      }
      logBodyStyle = {
        width: '100%',
        textAlign: 'center'
      }
    }

    const visible = ref(false);
    const showLogin = () => {
      let se = Cookies.get('p5session');
      if (se != undefined) {
        ctx.emit('callPage', 'AdminPage')
      } else {
        visible.value = true;
      }
    };

    let switchPage = (page) => {
      ctx.emit('callPage', page)
    }

    let poetryBody = () => {
      axios.post('/api/getArticleRand').then(res => {
        let data = [];
        if (res.data.status == 'YES') {
          for (let p of res.data.data) {
            data.push({
              title: (p.chinese_name == '' ? p.name : p.chinese_name) + (p.translator_name != '' ? ' / ' + p.translator_name : ''),
              data: p
            })
            ctx.emit('callPage', { "page": 'PoetryBody', "data": { data: data, type: '随机顺序', index: 1, page: 1 } });
          }
          console.log(res);
        }
      })

    }

    return {
      visible,
      showLogin,
      logInStyle,
      logBodyStyle,
      switchPage,
      poetryBody
    }
  },

  name: 'MenuHome',
  data: function () {
    return {
      password: undefined,
      username: undefined,
      vertical: document.body.clientWidth > 876 ? false : true,
      showIcon: document.body.clientWidth > 876 ? false : true,
    }
  },
  props: {
    currentProp: {
      type: Object,
    },
  },
  methods: {
    logIn: function () {
      let _this = this;
      if (this.username != undefined && this.password != undefined) {
        axios.post('/api/login', { "username": this.username, "password": md5(this.password + '') }).then(res => {
          if (res.data.status == 'YES') {
            Cookies.set("p5session", res.data.cookie, { expires: 6.9, path: '' });
            Cookies.set("aaa", "res.data.cookie", { expires: 7, path: '' });
            _this.visible = false
          } else {
            P5Notification({
              content: '登录失败，请重试', character: 'mona',
              top: document.body.clientHeight * 0.2, left: document.body.clientWidth * 0.1
            })
          }

        })

      }
    },
    collapse:function(collapsed, type){
      this.showIcon = collapsed
    }
  },
  emits: ["callPage"],
  mounted: function(){
    if(this.vertical)
    {
      let box = document.getElementsByClassName('arco-menu-collapse-button');
      console.log(box);
      console.log(box[0].innerHTML); 
      // box[0].innerHTML = `<svg viewBox="0 0 48 48" fill="none" stroke="currentColor" class="arco-icon arco-icon-menu-fold" stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter"><path d="M42 11H6M42 24H22M42 37H6M13.66 26.912l-4.82-3.118 4.82-3.118v6.236Z"></path></svg>`
    }
  }
}
</script>

<style scoped>
.transparent {
  background-color: rgb(255, 0, 0, 0) !important;
}

.menu-home {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  padding: 10px;
  background-color: var(--color-neutral-2);
  /* background-color: rgba(255, 239, 239, 0.514) */
}



.menu-demo .arco-menu {
  width: 150px;
  height: 100%;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.3); */
}

.menu-demo .arco-menu :deep(.arco-menu-collapse-button) {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.menu-demo .arco-menu:not(.arco-menu-collapsed) :deep(.arco-menu-collapse-button) {
  right: 0;
  transform: translateX(50%);
}

.menu-demo .arco-menu:not(.arco-menu-collapsed)::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48px;
  /* height: 48px; */
  background-color: inherit;
  border-radius: 50%;
  /* box-shadow: -4px 0 2px var(--color-bg-2), 0 0 1px rgba(0, 0, 0, 0.3); */
  transform: translateX(50%);
}

.menu-demo .arco-menu.arco-menu-collapsed {
  width: 48px;
  height: auto;
  padding-bottom: 138px;
  border-radius: 22px;
}

.menu-demo .arco-menu.arco-menu-collapsed :deep(.arco-menu-collapse-button) {
  right: 8px;
  bottom: 8px;
}
</style>